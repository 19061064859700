@import "bootstrap/bootstrap";
@import "fonts";

@images-path: '../../public/images/';
@font-path: '../../public/fonts/';

@margin-base: 30px;
@padding-base: 30px;

@brand-secondary: #7f8c8c;
@brand-white: #fff;

@gray-background: #e1e1e1;

.section-background-image(@background-url) {
  background: url(@background-url) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

body {
  @media (max-width: @screen-sm-min) {
    font-size: 20px;

    .navbar {
      .navbar-brand {
        width: 80%;
      }
    }
  }

  @media (max-width: @screen-xl-min) {

  }
}
.main-container {
  padding-top: 80px;

  .gray-background {
    background-color: @gray-background;
  }

  .row {
    & > {
      padding-bottom: @padding-base;
    }
    .title,
    .subtitle {
      font-family: 'Intro';
      text-transform: uppercase;
      margin: @margin-base 0px;
      @media (max-width: @screen-sm-min) {
        font-size: 30px;
      }
    }
    .title {
      color: @brand-primary;
      text-align: center;
    }
    .subtitle {
      color: @brand-secondary;
    }
    .block {
      float: left;
      background-color: @brand-white;
      margin-right: 1.6666666667%;
      width: unit((90 / 7), %);

      @media (max-width: @screen-md-min) {
        width: 100%;

         + .block {
           margin-top: @margin-base;;
         }
      }

      &:last-child {
        margin-right: 0;
      }
      box-shadow: -2px 2px 0px #e5e6e6;
      padding: 20px 10px;
      height: 213px;

      img {
        display: block;
        margin: 0 auto;
        padding-bottom: 10px;
      }
      .subtitle {
        margin: 0;
        span {
          display: block;
          font-size: 13px;
        }
      }
    }

    &#inicio {
      .col {
        padding: 0;

        .slick-wrapper {
          position: relative;

          .slick-mandarinas {
            overflow: hidden;

            .slide {
              height: 700px;

              &.slide-1 {
                .section-background-image('@{images-path}main-slider-1.png');
              }
              &.slide-2 {
                .section-background-image('@{images-path}main-slider-2.png');
              }
              &.slide-3 {
                .section-background-image('@{images-path}main-slider-3.png');
              }
            }
          }

          .slick-logo {
            position: absolute;
            right: 30px;
            top: 20px;
          }
        }
      }
    }

    &#acerca {
      .wrapper {
        background-color: @gray-background;
        border-radius: 5px;
        margin-bottom: @margin-base;
        .btn {
          font-size: 22px!important;
          border-radius: 5px;
          margin-top: 30%;
          @media (max-width: @screen-sm-min) {
            margin-top: 0px;
          }
        }
      }
    }

    &#planos {
      background: @gray-background url('@{images-path}regla-path.png');
      background-repeat: repeat-x;
    }

    &#quienes-somos {
      .section-background-image('@{images-path}background-quienes-somos.png');
      .content {
        background-color: fade(#3c3c3c, 80%);
        padding: 50px 75px;
        color: @brand-white;

        @media (max-width: @screen-sm-min) {
          padding: 20px;
        }
      }
    }

    &#especificaciones {
      .section-background-image('@{images-path}background-especificaciones.png');

      .container{
        background-image: url('@{images-path}especificaciones-lineas.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        padding-bottom: 60px;
        font-size: @font-size-small;
        .list-unstyled {
          > li {
            background-image: url('@{images-path}bullet.png');
            background-repeat: no-repeat;
            background-position: 0px 12px;
            padding-left: 15px;
            > ul {
              padding: 0;
              margin: 0;
              li {
                list-style: none;
                font-weight: 100;
              }
            }
            + li {
              margin-top: 30px;
            }
          }
        }
      }
    }

    &#avance {
      .wrapper {
        position: relative;

        .background-avance {
          background-image: url('@{images-path}background-avance.png');
          height: 650px;
          width: 100%;
        }

        .content {
          background-color: fade(#000, 60%);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          color: @brand-white;

          .content-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            .translate(-50%, -50%);

            span {
              font-weight: bold;
              display: block;
              &:first-child {
                font-size: 137px;
              }
              &:last-child {
                font-size: 63px;
              }
            }
          }
        }
      }
    }

    &#ubicacion {
      .section-background-image('@{images-path}background-ubicacion.png');
      color: @brand-secondary;
    }

    &#contactanos {
      .section-background-image('@{images-path}background-contactanos.png');

      .btn {
        background-color: #000;
        font-size: 22px;
        color: @brand-white;
      }
    }
  }
}

footer {
  background-image: url('@{images-path}background-footer.png');
  background-repeat: repeat-x;
  background-size: contain;
  color: @brand-white;
  .footer-wrapper {
    background-image: url('@{images-path}regla-path.png');
    background-repeat: repeat-x;
    padding: 50px 0px;

    @media (max-width: @screen-sm-min) {
      padding-bottom: 80px;
      .col {
        text-align: center!important;
      }
    }

    .logo {
      display: inline-block;
    }
  }
}

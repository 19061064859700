@font-face {
  font-family: 'Intro';
  src: url('@{font-path}app/intro/Intro.eot');
  src: url('@{font-path}app/intro/Intro.woff2') format('woff2'),
       url('@{font-path}app/intro/Intro.woff') format('woff'),
       url('@{font-path}app/intro/Intro.ttf') format('truetype'),
       url('@{font-path}app/intro/Intro.svg#Intro') format('svg'),
       url('@{font-path}app/intro/Intro.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe';
  src: url('@{font-path}app/segoe/Segoe.eot');
  src: url('@{font-path}app/segoe/Segoe.woff2') format('woff2'),
       url('@{font-path}app/segoe/Segoe.woff') format('woff'),
       url('@{font-path}app/segoe/Segoe.ttf') format('truetype'),
       url('@{font-path}app/segoe/Segoe.svg#Intro') format('svg'),
       url('@{font-path}app/segoe/Segoe.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe';
  src: url('@{font-path}app/segoe/Segoe-Light.eot');
  src: url('@{font-path}app/segoe/Segoe-Light.woff2') format('woff2'),
       url('@{font-path}app/segoe/Segoe-Light.woff') format('woff'),
       url('@{font-path}app/segoe/Segoe-Light.ttf') format('truetype'),
       url('@{font-path}app/segoe/Segoe-Light.svg#Intro') format('svg'),
       url('@{font-path}app/segoe/Segoe-Light.eot?#iefix') format('embedded-opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe';
  src: url('@{font-path}app/segoe/Segoe-Bold.eot');
  src: url('@{font-path}app/segoe/Segoe-Bold.woff2') format('woff2'),
       url('@{font-path}app/segoe/Segoe-Bold.woff') format('woff'),
       url('@{font-path}app/segoe/Segoe-Bold.ttf') format('truetype'),
       url('@{font-path}app/segoe/Segoe-Bold.svg#Intro') format('svg'),
       url('@{font-path}app/segoe/Segoe-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: 900;
  font-style: normal;
}
